import React, { useState,useEffect } from 'react';
import { Typography,Paper, Grid, makeStyles, Hidden,Box, Divider,Button,Checkbox, FormControlLabel } from "@material-ui/core";
import images from '../../img/images';
import { useLocation } from "react-router-dom";
import {fetchInfoTiers,fetchCreateUserFct, fetchInfoTiersWithIdc} from "./InscriptionApi";
import {InscriptionInfoTiers,InscriptionInfoContact,UserCreation,UserCreationProps, UserExistantDto} from "./InscriptionType";
import { useDispatch } from 'react-redux';
import MyMaterialTable from "../../components/MyMaterialTable";
import {  useMobileMode,formatDate,formatNumber,isEmptyVal,upperFirstLetter } from '../../utils/utils';
import { Column } from 'material-table';
import { ChangeHandler } from '../../utils/types';
import MyTextField,{MyNumberTextField} from "../../components/MyTextField";
import { useGlobalStyles } from "../../style/globalStyles";
import clsx from "clsx";
import colors from '../../style/colors';
import { KeyboardDatePicker,MuiPickersUtilsProvider  } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { fr } from "date-fns/locale";
import InputMask from "react-input-mask";
import DialogNotification, { DialogNotificationObject } from '../../page/DialogNotification';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding:10
  },
  divider: {
    margin: "20px 0",
    backgroundColor: colors.noir +"! important"
  }, paperConfirm: {
    padding: theme.spacing(10)
  },
  paperText: {
    fontWeight: theme.typography.fontWeightMedium
  },cadreInscription:{
    border: "1px solid #32a1ce",
    borderRadius: "2px",
    paddingBottom:"20px",
    height:"200px",
    overflow:"auto",
    margin: "5px"
  }
}))

const InscriptionPage = () => {
  const { paper, divider, paperText,paperConfirm } = useStyles();
  const location = useLocation();
  const dispatch = useDispatch(); 
  //const mobileMode = useMobileMode();
  const { margeContainer } = useGlobalStyles();

 function rechercheParametre(key: string){
    const tabParams = location.search.replace("?","").split("&");
    if(tabParams){
        for (const param of tabParams) {
            if(param){
                if(param.split("=")[0] === key){
                    return param.split("=")[1]
                }
            }
        }
    }
  }

  const [showError,setShowError] = useState<boolean>(false);
  const [codetiers,setCodetiers]= useState<string>(null);
  const [idc,setIdc]= useState<string>(null);
  const [infoTiers,setInfoTiers]= useState<InscriptionInfoTiers>(null);
  const [selectectContact, setSelectectContact] = useState<InscriptionInfoContact>();
  const [userExistant,setUserExistant]= useState<UserExistantDto>(null);
  const [notifUserExistant,setNotifUserExistant]= useState<DialogNotificationObject>(null);

  const [cG, setCG] = useState(false);
  const onCGToggle = () => { setCG(!cG); }
  const messageGCP = (infoTiers && !isEmptyVal(infoTiers.urlCGP))?"Je reconnais avoir pris connaissance des <a target='_blank' href='"+infoTiers.urlCGP+"' >Conditions Générales de Participation</a>":"";

  const [nom,setNom]= useState<string>(null);
  const [prenom,setPrenom]= useState<string>(null);
  const [email,setEmail]= useState<string>(null);
  const [numeroTelephone,setNumeroTelephone]= useState<string>(null);
  const [dateNaissance,setDateNaissance]= useState(null);
  const [sau,setSau]= useState<number>(null);
  const [surfaceHerbe,setSurfaceHerbe]= useState<number>(null);
  const [isInscriptionOk,setIsInscriptionOk]= useState<boolean>(false);
  const [dateIsValid, setDateIsValid] = useState<boolean>(false);

  const [checkSMS,setCheckSMS] = useState<boolean>(false);
  const [checkMail,setCheckMail] = useState<boolean>(false);
  const [infoactuOui,setInfoactuOui] = useState<boolean>(false);
  const [infoactuNon,setInfoactuNon] = useState<boolean>(false);
  const [genre,setGenre]= useState<string>(null);

  const handleNomChange: ChangeHandler = e => setNom(!isEmptyVal(e.target.value)?e.target.value.toUpperCase():e.target.value)
  const handlePrenomChange: ChangeHandler = e => setPrenom(!isEmptyVal(e.target.value)?upperFirstLetter(e.target.value):e.target.value)
  const handleEmailChange: ChangeHandler = e => setEmail(e.target.value)
  const handleNumTelChange: ChangeHandler = e => setNumeroTelephone(e.target.value)
 
  const onToggleCheckSMS: ChangeHandler = () => {setCheckSMS(!checkSMS); }
  const onToggleCheckMail: ChangeHandler = () => {setCheckMail(!checkMail);}

  const onToggleInfoactuOui: ChangeHandler = () => {setInfoactuOui(!infoactuOui);setInfoactuNon(false); }
  const onToggleInfoactuNon: ChangeHandler = () => {setInfoactuNon(!infoactuNon);setInfoactuOui(false); }

  const onToggleCheckHomme: ChangeHandler = (e) => {
    const target = e.target
    if (target.checked) {
        setGenre('H'); 
    }
  }

  const onToggleCheckFemme: ChangeHandler = (e) => {
    const target = e.target
    if (target.checked) {
        setGenre('F'); 
    }
  }

  const handleInputDateError = (error: any) => {
    if (error) {
        setDateIsValid(false);
    } else {
        setDateIsValid(true);
    }
  };


  {/* MON EXPLOITATION */}
  const [cerealier,setCerealier] = useState<boolean>(false);
  const [eleveur,setEleveur] = useState<boolean>(false);
  const [arboriculteur,setArboriculteur] = useState<boolean>(false);
  const [autre,setAutre] = useState<boolean>(false);
  const [repAutre,setRepAutre]= useState<string>(null);

  const onToggleCerealier: ChangeHandler = () => {setCerealier(!cerealier); }
  const onToggleEleveur: ChangeHandler = () => {setEleveur(!eleveur); }
  const onToggleArboriculteur: ChangeHandler = () => {setArboriculteur(!arboriculteur); }
  const onToggleAutre: ChangeHandler = () => {setAutre(!autre);if(autre){setRepAutre("")} }
  const handleRepAutre: ChangeHandler = e => setRepAutre(e.target.value);


    {/* ELEVEUR */}
    const [bovin,setBovin] = useState<boolean>(false);
    const [ovin,setOvin] = useState<boolean>(false);
    const [porcin,setPorcin] = useState<boolean>(false);
    const [autreEleveur,setAutreEleveur] = useState<boolean>(false);
    const [repAutreEleveur,setRepAutreEleveur]= useState<string>(null);
    const [nbBovin,setNbBovin]= useState<number>(null);
    const [nbOvin,setNbOvin]= useState<number>(null);
    const [nbPorcin,setNbPorcin]= useState<number>(null);
  
    const onToggleBovin: ChangeHandler = () => {setBovin(!bovin);if(bovin){setNbBovin(null);} }
    const onToggleOvin: ChangeHandler = () => {setOvin(!ovin); if(ovin){setNbOvin(null);}}
    const onTogglePorcin: ChangeHandler = () => {setPorcin(!porcin);if(porcin){setNbBovin(null);} }
    const onToggleAutreEleveur: ChangeHandler = () => {setAutreEleveur(!autreEleveur);if(autreEleveur){setRepAutreEleveur("")} }
    const handleRepAutreEleveur: ChangeHandler = e => setRepAutreEleveur(e.target.value);


    const regExEmail = new RegExp('^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$');

  useEffect(() => {
    if(!isEmptyVal(rechercheParametre("codetiers"))){
        setCodetiers(rechercheParametre("codetiers"));
    }
    if(!isEmptyVal(rechercheParametre("idc"))){
        setIdc(rechercheParametre("idc"));
    }
    
   }, []);

   function selectContact(data: InscriptionInfoContact){
        if(data){    
            if(!data.exists){
                setSelectectContact(data);
                setNom(data.nom);
                setPrenom(data.prenom);
                setEmail(data.email?data.email:"");
                setNumeroTelephone(data.gsm?data.gsm:"");
                const dateFns: DateFnsUtils = new DateFnsUtils();
                if(!isEmptyVal(data.dateNaissance)){
                    setDateNaissance( dateFns.parse(data.dateNaissance,"dd/MM/yyyy"));
                }else{
                    setDateNaissance(null);
                }
            }
        }else{
            setSelectectContact(null);
        }
   }

  useEffect(() => {
    function initInfoTiers(data: InscriptionInfoTiers) {
        setInfoTiers(data);
        if(data && data.userExistant){
            setUserExistant(data.userExistant)
        }
        if(data && data.tiers){
            setCerealier(data.tiers.cerealier);
            setEleveur(data.tiers.eleveur);
            setBovin(data.tiers.bovin);
            setOvin(data.tiers.ovin);
            setSurfaceHerbe(data.tiers.surfacePrairie>0?data.tiers.surfacePrairie:null);
            setSau(data.tiers.sau>0?data.tiers.sau:null);
        }
    }
    if(codetiers!==null){
        dispatch(fetchInfoTiers(codetiers,initInfoTiers));
    }
    
}, [codetiers]);

useEffect(() => {
    if(userExistant){
        const notification: DialogNotificationObject = {
            title: "Un compte à été trouvé",
            body: userExistant.actif?`Un compte au nom de ${userExistant.nom} actif a été trouvé voulez-vous vous connecter ?`:`Un compte au nom de ${userExistant.nom} non activé a été trouvé voulez vous terminer l'inscription de ce compte ?`,
        }
        setNotifUserExistant(notification)
    }    
}, [userExistant]);


useEffect(() => {
    function initInfoTiers(data: InscriptionInfoTiers) {
        setInfoTiers(data);
        if(data && data.userExistant){
            setUserExistant(data.userExistant)
        }
        if(data && data.tiers){
            setCerealier(data.tiers.cerealier);
            setEleveur(data.tiers.eleveur);
            setBovin(data.tiers.bovin);
            setOvin(data.tiers.ovin);
            setSurfaceHerbe(data.tiers.surfacePrairie>0?data.tiers.surfacePrairie:null);
            setSau(data.tiers.sau>0?data.tiers.sau:null);
        }
    }
    if(idc!==null){
        dispatch(fetchInfoTiersWithIdc(idc,initInfoTiers));
    }
    
}, [idc]);

const validForm: boolean=
                 !isEmptyVal(nom) // nom!=null && !="" && nom!=undefined
                && !isEmptyVal(prenom) //prenom!=null && prenom!="" && prenom!=undefined
                && !isEmptyVal(email) && regExEmail.test(email)
                && !isEmptyVal(numeroTelephone) && !numeroTelephone?.includes("_")
                && !isEmptyVal(dateNaissance) 
                && (checkMail || checkSMS)
                && (infoactuNon || infoactuOui)
                && (cerealier || eleveur || arboriculteur || autre)
                //&& (cerealier && surfaceHerbe>0)
                //&& ((cerealier||arboriculteur) && sau>0)
                && ((eleveur && (bovin || ovin || autreEleveur || porcin)) || !eleveur)
                && !(eleveur && autreEleveur && (repAutreEleveur==="" || repAutreEleveur===null))
                && !(eleveur && bovin && nbBovin<=0)
                && !(eleveur && ovin && nbOvin<=0)
                && !(eleveur && porcin && nbPorcin<=0)
                && dateIsValid
                && !isEmptyVal(genre) && genre.trim() !== ""
                && cG;


function addProps(code: string,valeur: string){
    const prop: UserCreationProps={
        code: code,
        valeur: valeur
    }
    return prop;
}  

function enregistrer(){
    function resultCreateUser(data: string){
        if(!isEmptyVal(data)){
            //setIsInscriptionOk(true);
            window.location.href = "/e-agriskop/ValidationInscription?h="+data;
        }
    }

    if(validForm){
        const userProps: UserCreationProps[] = [];
        userProps.push(addProps("CLE_CONTACT",(selectectContact && selectectContact.nom===nom && selectectContact.prenom===prenom)?selectectContact.cleContact:""));
        userProps.push(addProps("TYPE_CEREALIER",cerealier?"O":"N"));
        userProps.push(addProps("INFO_ACTU",infoactuOui?"O":"N"));
        userProps.push(addProps("TYPE_ELEVEUR",eleveur?"O":"N"));
        userProps.push(addProps("TYPE_ARBORICULTEUR",arboriculteur?"O":"N"));
        userProps.push(addProps("TYPE_AUTRE",autre?repAutre:""));
        if((cerealier||arboriculteur)){userProps.push(addProps("SAU",(sau>=0 && sau!==null && sau!==undefined)?formatNumber(sau,3):""));}
        if((cerealier)){userProps.push(addProps("SURFACE_HERBE",(surfaceHerbe>=0 && surfaceHerbe!==null && surfaceHerbe!==undefined)?formatNumber(surfaceHerbe,3):""));}
        if(eleveur){
            userProps.push(addProps("ELEVEUR_BOVIN",bovin?"O":"N"));
            if(bovin){userProps.push(addProps("BOVIN_NB",formatNumber(nbBovin,0)));}
            userProps.push(addProps("ELEVEUR_OVIN",ovin?"O":"N"));
            if(ovin){userProps.push(addProps("OVIN_NB",formatNumber(nbOvin,0)));}
            userProps.push(addProps("ELEVEUR_PORCIN",porcin?"O":"N"));
            if(porcin){userProps.push(addProps("PORCIN_NB",formatNumber(nbOvin,0)));}
            userProps.push(addProps("ELEVEUR_AUTRE",autreEleveur?repAutreEleveur:"N"));
        }
        userProps.push(addProps("GENRE",genre))
        
        const userCre: UserCreation={
            nom:nom,
            prenom:prenom,
            typeTiers: "AD",
            codeTiers: infoTiers.tiers.codeTiers,
            email: email,
            gsm: numeroTelephone,
            contactPrivilegie: (checkSMS?"SMS":"") + ((checkMail&&checkSMS)?"#":"") + (checkMail?"EMAIL":""),
            raisonSociale: (infoTiers.tiers.prenom?(infoTiers.tiers.prenom+" "):"")+ infoTiers.tiers.nomPrenom,
            dateNaissance: formatDate(dateNaissance),
            userProps: userProps,
            loginExtranet: (selectectContact && selectectContact.nom===nom && selectectContact.prenom===prenom)?selectectContact.loginExtranaute:""
        }
        dispatch(fetchCreateUserFct(userCre,resultCreateUser));
    }else{
        setShowError(true);
    }
    
}

/*function next(){
    dispatch(push(PATH + "/Login"))
}*/

const requiredAste = "<font color='"+colors.rouge+"'>*</font>";

  return (
    <>
    <Grid container direction="row">
        <Hidden xsDown><Grid item xs={3}></Grid></Hidden>
        <Grid item xs={12} sm={6}>
            <Paper className={paper}>
                <Grid container alignContent="center" justify="center" alignItems="center">
                    <Grid item xs={12}><img src={images.logos.emc2[0]} height={80} /></Grid>
                </Grid>
                {!isInscriptionOk && infoTiers && infoTiers.tiers && (
                    <>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h3" gutterBottom >{"Bonjour " +(infoTiers.tiers.prenom?(infoTiers.tiers.prenom+" "):"")+ infoTiers.tiers.nomPrenom}</Typography>
                            </Grid>
                        </Grid>
                        {infoTiers.tiers.contacts.length>0 &&(
                            <>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="h6" gutterBottom >{"Veuillez sélectionner un contact existant ou saisir les informations ci-dessous pour créer votre accès"}</Typography>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <Box className={margeContainer}>
                                                <Divider className={clsx(divider, margeContainer)} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="subtitle1" color="textPrimary"  gutterBottom align="left" >{"Contacts : "}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <BlocContact data={infoTiers.tiers.contacts} onRowClick={selectContact} selectedData={selectectContact} />
                                    </Grid>
                                </Grid>
                            </>

                        )}
                        {infoTiers.tiers.contacts.length==0 &&(
                            <>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="h6" gutterBottom >{"Veuillez saisir les informations ci-dessous pour créer votre accès"}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Box className={margeContainer}>
                                    <Divider className={clsx(divider, margeContainer)} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h3" color="textPrimary"  gutterBottom align="center" >{"Qui suis-je ?"}</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <Typography variant="h6" color="textPrimary"  gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Nom"+requiredAste }}></Typography>
                            </Grid>
                            <Grid item xs={9}  sm={9}>
                                <MyTextField fullWidth margin="normal" label="" value={nom} onChange={handleNomChange} inputProps={{ style: {padding:"6px"}}} style={{margin:"5px"}}
                                errorMsg={((isEmptyVal(nom))&&showError)?"Merci de saisir votre nom":""}/>
                            </Grid>
                            <Grid item xs={3}  sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Prénom"+requiredAste }}></Typography>
                            </Grid>
                            <Grid item xs={9}  sm={9}>
                                <MyTextField fullWidth margin="normal" label="" value={prenom} onChange={handlePrenomChange} inputProps={{ style: {padding:"6px"}}} style={{margin:"5px"}}
                                 errorMsg={((isEmptyVal(prenom))&&showError)?"Merci de saisir votre prénom":""}/>
                            </Grid>
                            <Grid item xs={3}  sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Date de naissance"+requiredAste }}></Typography>
                            </Grid>
                            <Grid item xs={9}  sm={9}>
                                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        views={["year", "month", "date"]}
                                        openTo="year"
                                        autoOk
                                        format="dd/MM/yyyy"
                                        value={dateNaissance}
                                        onChange={setDateNaissance}
                                        disableFuture
                                        animateYearScrolling
                                        variant="inline"
                                        inputVariant="outlined"
                                        inputProps={{ style: {padding:"6px"}}}
                                        style={{marginLeft:"5px"}}
                                        clearable
                                        clearLabel={"Effacer"}
                                        onError={handleInputDateError}
                                        invalidDateMessage={"Format invalide, le format doit être JJ/MM/AAAA"}
                                        maxDateMessage={"La date de naissance ne peut être dans le futur"}
                                        required={true}
                                        
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            {showError && (isEmptyVal(dateNaissance)) &&
                            (
                                <>
                                    <Grid item xs={3}  sm={3}></Grid>
                                    <Grid item xs={9}  sm={9}>
                                        <Typography variant="subtitle2" color="error">{"Vous devez saisir une date de naissance"}</Typography>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}  sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Genre"+requiredAste }}></Typography>
                            </Grid>
                            <Hidden mdUp><Grid item xs={3}></Grid></Hidden> 
                            <Grid item xs={4}  sm={2}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={genre === 'H'} 
                                                            onChange={onToggleCheckHomme} 
                                                            />} 
                                label="Homme" />
                            </Grid>
                            <Grid item xs={5}  sm={7}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={genre === 'F'} 
                                                            onChange={onToggleCheckFemme} 
                                                            />} 
                                label="Femme" 
                                />
                            </Grid>
                            {showError && (isEmptyVal(genre) || (!isEmptyVal(genre) && genre.trim()=== "")) &&
                            (
                                <>
                                    <Grid item xs={3}  sm={3}></Grid>
                                    <Grid item xs={9}  sm={9}>
                                        <Typography variant="subtitle2" color="error">{"Vous devez sélectionner une des options"}</Typography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Box className={margeContainer}>
                                    <Divider className={clsx(divider, margeContainer)} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h3" color="textPrimary"  gutterBottom align="center" >{"Mes coordonnées"}</Typography>
                            </Grid>
                            <Grid item xs={3}  sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Email"+requiredAste }}></Typography>
                            </Grid>
                            <Grid item xs={9}  sm={9}>
                                <MyTextField fullWidth margin="normal" label="" value={email} onChange={handleEmailChange} inputProps={{ style: {padding:"6px"}}} style={{margin:"5px"}} 
                                errorMsg={((isEmptyVal(email) || !regExEmail.test(email))&&showError)?"Merci de saisir votre adresse email valide":""}/>
                            </Grid>
                            <Grid item xs={3}  sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Numero Téléphone"+requiredAste }}></Typography>
                            </Grid>
                            <Grid item xs={9}  sm={9}>
                                 <InputMask 
                                    mask='99 99 99 99 99' 
                                    value={numeroTelephone} 
                                    onChange={handleNumTelChange}
                                    style={{margin:"5px"}}
                                    
                                    >    
                                        <MyTextField fullWidth margin="normal" label="" inputProps={{ style: {padding:"6px"}}} style={{margin:"5px"}}
                                        errorMsg={((isEmptyVal(numeroTelephone) || numeroTelephone?.includes("_"))&&showError)?"Merci de saisir votre numéro de téléphone valide":""}/>
                                </InputMask>
                            </Grid>
                            <Grid item xs={12}  sm={12}>
                                <Typography variant="subtitle2" color="textPrimary" gutterBottom align="left" >{"Dans le cadre de ce panel, nous serons amené à vous contacter : enquête est ouverte, résultat d'enquête, actualité,..."}</Typography>
                            </Grid>
                            <Grid item xs={12}  sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Je souhaite être contacté de préférence par "+requiredAste }} ></Typography>
                            </Grid>
                            <Hidden mdUp><Grid item xs={3}></Grid></Hidden> 
                            <Grid item xs={4}  sm={2}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={checkSMS} 
                                                            onChange={onToggleCheckSMS} 
                                                            />} 
                                label="SMS" />
                            </Grid>
                            <Grid item xs={5}  sm={7}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={checkMail} 
                                                            onChange={onToggleCheckMail} 
                                                            />} 
                                label="Email" 
                                />
                            </Grid>
                            {showError && !(checkSMS || checkMail) && 
                            (
                                <>
                                    <Grid item xs={3}  sm={3}></Grid>
                                    <Grid item xs={9}  sm={9}>
                                        <Typography variant="subtitle2" color="error">{"Vous devez choisir un mode de contact"}</Typography>
                                    </Grid>
                                </>
                            )}


                            <Grid item xs={12}  sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Je souhaite être informé dès qu’il y a une nouvelle actualité sur Agriscoop"+requiredAste }} ></Typography>
                            </Grid>
                            <Hidden mdUp><Grid item xs={3}></Grid></Hidden> 
                            <Grid item xs={4}  sm={2}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={infoactuOui} 
                                                            onChange={onToggleInfoactuOui} 
                                                            />} 
                                label="OUI" />
                            </Grid>
                            <Grid item xs={5}  sm={7}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={infoactuNon} 
                                                            onChange={onToggleInfoactuNon} 
                                                            />} 
                                label="NON" 
                                />
                            </Grid>
                            {showError && !(infoactuNon || infoactuOui) && 
                            (
                                <>
                                    <Grid item xs={3}  sm={3}></Grid>
                                    <Grid item xs={9}  sm={9}>
                                        <Typography variant="subtitle2" color="error">{"Merci de répondre à la question"}</Typography>
                                    </Grid>
                                </>
                            )}

                            
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Box className={margeContainer}>
                                    <Divider className={clsx(divider, margeContainer)} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h3" color="textPrimary"  gutterBottom align="center" >{"Mon exploitation"}</Typography>
                            </Grid>
                            <Grid item xs={12}  sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Je suis"+requiredAste }}></Typography>
                            </Grid>
                            {showError && !(eleveur || cerealier || arboriculteur || autre) && 
                            (
                                <>
                                    <Hidden mdUp><Grid item xs={3}></Grid></Hidden> 
                                    <Grid item xs={9}  sm={9}>
                                        <Typography variant="subtitle2" color="error">{"Merci de sélectionner au moins une réponse"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}></Grid> 
                                </>
                            )}
                            
                            <Grid item xs={9}  sm={9}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={cerealier} 
                                                            onChange={onToggleCerealier} 
                                                            />} 
                                label="Céréalier" />
                            </Grid>
                            <Grid item xs={3}></Grid> 
                            <Grid item xs={9}  sm={9}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={eleveur} 
                                                            onChange={onToggleEleveur} 
                                                            />} 
                                label="Eleveur" />
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}  sm={9}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={arboriculteur} 
                                                            onChange={onToggleArboriculteur} 
                                                            />} 
                                label="Arboriculteur" />
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}  sm={2}>
                                <FormControlLabel control={<Checkbox 
                                                            color="primary" 
                                                            checked={autre} 
                                                            onChange={onToggleAutre} 
                                                            />} 
                                label="Autre" />
                            </Grid>
                            <Grid item xs={6}  sm={7}>
                                <MyTextField fullWidth margin="normal" label="" value={repAutre} onChange={handleRepAutre} inputProps={{ style: {padding:"6px"}}} style={{margin:"5px"}} 
                                errorMsg={(autre&&(isEmptyVal(repAutre))&&showError)?"Merci de préciser":""}/>
                            </Grid>
                        </Grid>

                        {(cerealier || arboriculteur) && (
                            <>
                                <Grid container>
                                    <Grid item xs={12}  sm={3} >
                                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"La Surface Agricole Utile de mon exploitation est de (en ha) :"}</Typography>
                                    </Grid>
                                    <Grid item xs={12}  sm={3}>
                                        <MyNumberTextField 
                                            min={0} 
                                            precision={0} 
                                            label="" 
                                            onNumberChange={setSau} 
                                            value={sau}
                                            inputProps={{ style: {padding:"6px",marginLeft:"5px"}}}
                                            //errorMsg={((sau<=0)&&showError)?"Merci de saisir une valeur":""}
                                            />
                                    </Grid>
                                    <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                </Grid>
                            </>
                        ) }
                        
                        {(cerealier) && (
                            <>
                                <Grid container>
                                    <Grid item xs={12}  sm={3} >
                                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"La surface en herbe de mon exploitation est de (en ha) :"}</Typography>
                                    </Grid>
                                    <Grid item xs={12}  sm={3}>
                                        <MyNumberTextField 
                                            min={0} 
                                            precision={0} 
                                            label="" 
                                            onNumberChange={setSurfaceHerbe} 
                                            value={surfaceHerbe}
                                            inputProps={{ style: {padding:"6px",marginLeft:"5px"}}}
                                            //errorMsg={((surfaceHerbe<=0)&&showError)?"Merci de saisir une valeur":""}
                                            />
                                    </Grid>
                                    <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                </Grid>
                            </>
                        ) }

                        {(eleveur) && (
                            <>
                                <Grid container>
                                    <Grid item xs={12}  sm={12}>
                                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Quels types d'animaux sont présents sur votre exploitation ?"+requiredAste }}></Typography>
                                    </Grid>
                                    {showError && !(bovin || ovin || porcin || autreEleveur) && 
                                    (
                                        <>
                                            <Grid item xs={3}  sm={3}></Grid>
                                            <Grid item xs={9}  sm={9}>
                                                <Typography variant="subtitle2" color="error">{"Merci de sélectionner au moins une réponse"}</Typography>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={3}></Grid> 
                                    <Grid item xs={9}  sm={9}>
                                        <FormControlLabel control={<Checkbox 
                                                                    color="primary" 
                                                                    checked={bovin} 
                                                                    onChange={onToggleBovin} 
                                                                    />} 
                                        label="Bovin" />
                                    </Grid>
                                    <Grid item xs={3}></Grid> 
                                    <Grid item xs={9}  sm={9}>
                                        <FormControlLabel control={<Checkbox 
                                                                    color="primary" 
                                                                    checked={ovin} 
                                                                    onChange={onToggleOvin} 
                                                                    />} 
                                        label="Ovin" />
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={9}  sm={9}>
                                        <FormControlLabel control={<Checkbox 
                                                                    color="primary" 
                                                                    checked={porcin} 
                                                                    onChange={onTogglePorcin} 
                                                                    />} 
                                        label="Porcin" />
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={3}  sm={2}>
                                        <FormControlLabel control={<Checkbox 
                                                                    color="primary" 
                                                                    checked={autreEleveur} 
                                                                    onChange={onToggleAutreEleveur} 
                                                                    />} 
                                        label="Autre" />
                                    </Grid>
                                    <Grid item xs={6}  sm={7}>
                                        <MyTextField 
                                            fullWidth 
                                            margin="normal" 
                                            label="" 
                                            value={repAutreEleveur} 
                                            onChange={handleRepAutreEleveur} 
                                            inputProps={{ style: {padding:"6px"}}} style={{margin:"5px"}} 
                                            errorMsg={((eleveur && autreEleveur && (repAutreEleveur==="" || repAutreEleveur===null))&&showError)?"Merci de préciser":""}
                                            />
                                    </Grid>
                                </Grid>

                                {(bovin) && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12}  sm={3} >
                                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Quel est le nombre de bovins de mon exploitation :"+requiredAste }}></Typography>
                                            </Grid>
                                            <Grid item xs={12}  sm={3}>
                                                <MyNumberTextField 
                                                    min={0} 
                                                    precision={0} 
                                                    label="" 
                                                    onNumberChange={setNbBovin} 
                                                    value={nbBovin}
                                                    inputProps={{ style: {padding:"6px",marginLeft:"5px"}}}
                                                    errorMsg={((eleveur && bovin && nbBovin<=0)&&showError)?"Merci de saisir un nombre":""}
                                                    />
                                            </Grid>
                                            <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                        </Grid>
                                    </>
                                ) }
                                {(ovin) && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12}  sm={3} >
                                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Quel est le nombre d' ovins de mon exploitation :"+requiredAste }} ></Typography>
                                            </Grid>
                                            <Grid item xs={12}  sm={3}>
                                                <MyNumberTextField 
                                                    min={0} 
                                                    precision={0} 
                                                    label="" 
                                                    onNumberChange={setNbOvin} 
                                                    value={nbOvin}
                                                    inputProps={{ style: {padding:"6px",marginLeft:"5px"}}}
                                                    errorMsg={((eleveur && ovin && nbOvin<=0)&&showError)?"Merci de saisir un nombre":""}
                                                    />
                                            </Grid>
                                            <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                        </Grid>
                                    </>
                                ) }
                                {(porcin) && (
                                    <>
                                        <Grid container>
                                            <Grid item xs={12}  sm={3} >
                                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "Quel est le nombre de porcins de mon exploitation : "+requiredAste }}></Typography>
                                            </Grid>
                                            <Grid item xs={12}  sm={3}>
                                                <MyNumberTextField 
                                                    min={0} 
                                                    precision={0} 
                                                    label="" 
                                                    onNumberChange={setNbPorcin} 
                                                    value={nbPorcin}
                                                    inputProps={{ style: {padding:"6px",marginLeft:"5px"}}}
                                                    errorMsg={((eleveur && porcin && nbPorcin<=0)&&showError)?"Merci de saisir un nombre":""}
                                                    />
                                            </Grid>
                                            <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                        </Grid>
                                    </>
                                ) }
                            </>
                        ) }

                        {/*CGP*/}
                        {!isEmptyVal(infoTiers.urlCGP) && 
                        (
                            <>
                                <Grid container style={{paddingTop:"10px"}}>
                                    <Grid item xs={12}  sm={3}>                                 
                                    </Grid>
                                    <Grid item xs={2}  sm={1}>
                                        <FormControlLabel control={<Checkbox 
                                                                    color="primary" 
                                                                    checked={cG} 
                                                                    onChange={onCGToggle} 
                                                                    />} 
                                        label="" />
                                    </Grid>
                                    <Grid item xs={10}  sm={8}>
                                        <Typography variant="h6" style={{marginBottom:"0px"}} color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: messageGCP }}></Typography>
                                    </Grid>
                                    {showError && (!cG) &&
                                    (
                                        <>
                                            <Grid item xs={12}  sm={3}></Grid>
                                            <Grid item xs={2}  sm={1}></Grid>
                                            <Grid item xs={10}  sm={8} style={{paddingBottom:"10px"}}>
                                                <Typography variant="subtitle2" color="error">{"Vous devez valider les conditions."}</Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </>
                        )}

                        {/*BOUTONS*/}
                        <Grid container spacing={10}>
                            {/*
                            <Grid item xs={12} sm={12}><Button fullWidth variant="contained" color="primary" onClick={enregistrer} disabled={!validForm && showError}>ENREGISTRER</Button></Grid>
                            */}
                            <Grid item xs={12} sm={12}><Button fullWidth variant="contained" color="primary" onClick={enregistrer}>ENREGISTRER</Button></Grid>
                        </Grid>
                    </>
                )}
                {!isInscriptionOk && infoTiers && !infoTiers.tiers && (
                    <>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h2" gutterBottom >{"Le Tiers n'est pas reconnu dans notre système"}</Typography>
                            </Grid>
                        </Grid>
                    </>
                )}

                {isInscriptionOk && (
                    <>
                        <Grid container spacing={10}>
                            <Grid item xs={12}><Typography variant="h3">CONFIRMATION INSCRIPTION</Typography></Grid>
                            <Grid item xs={12}>
                            <Paper elevation={0} className={paperConfirm}>
                                <Grid container>
                                    <Grid item xs={12}><Typography variant="body2" component="div" dangerouslySetInnerHTML={{ __html: "Votre accès à Agriscoop est créé.<BR>Vous allez recevoir un email pour activer votre compte." }} className={paperText} gutterBottom ></Typography></Grid>
                                    <Grid item xs={12}><Typography variant="h6">{"Si vous ne recevez pas d'email pensez à verifier vos spams/mails indésirables."}</Typography></Grid>
                                    <Grid item xs={12}><Typography variant="h6">{"Vous pouvez nous contacter par courriel à contact@agriscoop.fr."}</Typography></Grid>
                                </Grid>
                            </Paper>
                            </Grid>
                            {/*<Grid item xs={12} sm={6}><Button fullWidth variant="contained" color="primary" onClick={next}>ME CONNECTER</Button></Grid>*/}
                        </Grid>
                    </>
                )}
                
            </Paper>
        </Grid>
    <Hidden xsDown><Grid item xs={3}></Grid></Hidden>
    </Grid>
    {(userExistant && notifUserExistant) && (
          <DialogNotification 
            onClose={() => {setUserExistant(null); setNotifUserExistant(null)}} 
            onConfirm={() => {window.location.href = userExistant.actif?"/e-agriskop/Login?login="+userExistant.idCrypt:"/e-agriskop/ValidationInscription?h="+userExistant.idCrypt}} 
            confirmText={userExistant.actif?"Me connecter":"Terminer l'inscription"} 
            closeText={"Annuler"} 
            notification={notifUserExistant} />
    )}
    </>
  );
}

export default InscriptionPage;

export interface BlocTableProps<T extends object> {
    data: T[];
    selectedData?: T[] | T;
    selectionChange?: (rows: T[]) => void;
    onRowClick?: (row: T) => void;
  }

  export const BlocContact = (props: BlocTableProps<InscriptionInfoContact>) => {

    const { data: contact, onRowClick, selectedData } = props;
    const mobileMode = useMobileMode();
    const [data, setData] = useState([]);
    const { cadreInscription } = useStyles();

    useEffect(() => {
        setData(contact?.map(co => ({ ...co })));
      }, [contact]);

    const columns: Column<InscriptionInfoContact>[] = [
        { title: "Nom", field: "nom" },
        { title: "Prénom", field: "prenom" },
        { title: "N° Téléphone", field: "gsm" },
        { title: "Email", field: "email" },
        { title: "", render: sc => sc.exists?<Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html:"<font color='"+colors.rouge+"'>Compte existant</font>"}}></Typography>:"" ,align: "left" }
      ];

      const isRowSelected = (rowData: InscriptionInfoContact) => selectedData && rowData.nom === (selectedData as InscriptionInfoContact).nom && rowData.prenom === (selectedData as InscriptionInfoContact).prenom;
      
      return <div className={cadreInscription}>
            <MyMaterialTable<InscriptionInfoContact> size={mobileMode ? "xs" : "small"} values={data} columns={columns} onRowClick={onRowClick} sort isRowSelected={isRowSelected} />
      </div>;

} 