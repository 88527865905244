import 'reactjs-popup/dist/index.css';
import {Grid,Typography ,Button,TextareaAutosize,makeStyles,createStyles,Backdrop} from '@material-ui/core';
import React, {useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useMobileMode } from '../../utils/utils';
import { RootState } from '../../app/rootReducer';
import { NavElement,ChangeHandlerTextArea} from '../../utils/types';
import PageTemplate from '../../page/PageTemplate';
import EnqueteSendUpFrame from './EnqueteSendupFrame';
import InfoEnquetePaper from "./InfoEnquetePaper";
import { PATH } from "../../utils/constants";
import { push } from "connected-react-router";
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import {fetchValidationPublication,fetchSaveCommentairePublication} from "./PublicationApi";
import { useCurrentUser } from '../auth/authApi';
import colors from '../../style/colors';
import Confetti from 'react-confetti'

const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      marginBottom: 10
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: colors.blanc,
      flexDirection: "column",
      backgroundColor:"transparent"
    }
  }));

const EnquetePage = () => {

  const mobileMode = useMobileMode();
  const {enquete} = useSelector((state: RootState) => state.enquete); 
  const dispatch = useDispatch();
  const [step, setStep] = useState<number>(0);
  const {divider,backdrop} = useStyles();
  const [ratingValue, setRatingValue] = useState<number>(3);
  const [commentaire, setCommentaire] = useState<string>(null);
  const user = useCurrentUser();
  const NAV_ELEMENTS: NavElement[] = [{ name: enquete?enquete.titre:""}];


  /* POP MERCI */
  const [openMerci, setOpenMerci] = useState<boolean>(false);
 
  useEffect(() => {
    if(openMerci) {
      const timer = setTimeout(() => setOpenMerci(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [openMerci]);
  
  const changeCommentaire: ChangeHandlerTextArea = e => setCommentaire(e.target.value);

  function exitSave(idSheet: number){
    // LOG VALIDATION 
    function resLog(data: boolean){
      if(data){
        // PERMET D AFFICHER L'IMAGE DE REMERCIMENT 
        setOpenMerci(true);
        setStep(1);
        // EVENT ENQUETE VALIDE
        window.parent.postMessage({type: "validenquete"}, '*');
      }
    }
    dispatch(fetchValidationPublication(user,enquete.id,''+idSheet,resLog));
  }

  const cancel = () => dispatch(push(PATH + "/Accueil"));

  function saveCommentaireNote(){

    function resCom(data: boolean){
      if(data){
        dispatch(push(PATH + "/Accueil"));
      }
    }
    dispatch(fetchSaveCommentairePublication(user,enquete.id,commentaire,ratingValue,resCom));
  }

  return (
    <PageTemplate  elements={NAV_ELEMENTS}>
      <Grid container spacing={mobileMode ? 0 : 10}>
        {step===0 && enquete && (
          <>
            { enquete.type==="ENQUETE" && enquete.nombreEpis > 0 && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h3" gutterBottom >{"Répondre à cette enquête vous permettra de gagner " +enquete.nombreEpis +" épis."}</Typography>
            </Grid>
            )}
            <Grid item xs={12} sm={9}>
                {enquete.provider==="SENDUP" &&
                (
                  <EnqueteSendUpFrame enquete={enquete.propsSendup} exitSave={exitSave}/>
                )}
            </Grid> 
            <Grid item xs={12} sm={3}>
                  {enquete.description && (
                  <>
                    <InfoEnquetePaper titre={enquete.titre} message={enquete.description} />
                  </>
                  )}
            </Grid>
          </>
        )}
        {step===1 && enquete && (
          <>
            {!openMerci && (
              <>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Grid item xs={12} sm={12}><Typography variant="h3" gutterBottom >{"Merci d'avoir répondu à l'enquête."}</Typography></Grid>
                    {enquete.type==="ENQUETE" && enquete.nombreEpis > 0 &&
                      (
                        <Grid item xs={12} sm={12}><Typography variant="h3" gutterBottom >{"Vous avez gagné " +enquete.nombreEpis +" épis."}</Typography></Grid>  
                      ) 
                    } 
                    
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h5" gutterBottom >{"Avez vous trouvé le sujet de cette enquête intéressant ?"}</Typography> 
                    </Grid>
                    <Grid item xs={12} sm={12} className={divider}>
                      <Rating
                        name="customized-icons"
                        defaultValue={ratingValue}
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                        size="large" 
                        onChange={(event, newValue) => {
                          setRatingValue(newValue);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} className={divider}>
                      <Typography variant="h5" gutterBottom >{"Avez vous un commentaire à nous faire remonter ?"}</Typography> 
                    </Grid>
                    <Grid item xs={12} sm={12} className={divider}>
                        <TextareaAutosize
                          rowsMin={5}
                          placeholder="Mon Commentaire"
                          style={{ width: 500 }}
                          value={commentaire}
                          onChange={changeCommentaire}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}><Button fullWidth size="small" variant="contained" color="primary" onClick={saveCommentaireNote}>{"ENVOYER NOTE ET COMMENTAIRE"}</Button></Grid> 
                    <Grid item xs={12} sm={6}><Button fullWidth size="small" variant="outlined" color="primary" onClick={cancel}>{"RETOUR À LA PAGE D'ACCUEIL"}</Button></Grid> 
                  </Grid>     
              </Grid> 
              <Grid item xs={12} sm={3}>
              </Grid>
            </>
            )}
            {openMerci &&
            (
              <>
                <Backdrop open={openMerci} className={backdrop}>
                  <Typography variant="h1" align="center" color="inherit" style={{fontSize: mobileMode?42:60,color:colors.bleu,marginTop:mobileMode?"145px":"0px"}}>{"Merci d'avoir répondu à l'enquête!"}</Typography>
                    <Confetti colors={[colors.bleu,colors.jaune]} numberOfPieces={300} ></Confetti>
                </Backdrop>
              </>
            )}
            
          </>
        )}
      </Grid> 
    </PageTemplate >
  );
}

export default EnquetePage;